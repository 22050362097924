import React, {useEffect, useRef} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const TypingEffect2 = ({children}) => {
  const textRef = useRef(null);

  useEffect(() => {
    if (!textRef.current) return;

    // 자식 노드를 배열로 가져오기
    const nodes = Array.from(textRef.current.childNodes);

    // 자식 요소를 모두 제거
    textRef.current.innerHTML = '';
    nodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        // 텍스트 노드일 경우 각 문자를 span으로 감싸기
        node.textContent.split('').forEach((char) => {
          const span = document.createElement('span');
          span.textContent = char;
          span.style.opacity = 0; // 초기에는 보이지 않도록 설정
          textRef.current.appendChild(span);
        });
      } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'BR') {
        // <br> 태그 처리: mo-br 및 pc-br 클래스에 맞게 그대로 추가
        const brClone = node.cloneNode(true);
        textRef.current.appendChild(brClone);
      } else {
        // 기타 요소 노드일 경우 그대로 추가 (예: <span>, <strong> 등)
        const clonedNode = node.cloneNode(true);
        clonedNode.style.opacity = 0; // 초기에는 보이지 않도록 설정
        textRef.current.appendChild(clonedNode);
      }
    });

    const mm = gsap.matchMedia();
    mm.add(
      {
        isMobile: '(max-width: 768px)',
        isDesktop: '(min-width: 769px)',
      },
      (context) => {
        let {isMobile} = context.conditions;

        // 모든 span 요소 및 요소 노드 애니메이션 설정
        gsap.fromTo(
          textRef.current.querySelectorAll('span, .mo-br, .pc-br'), // span과 반응형 br 요소 선택
          {opacity: 0}, // 초기 상태
          {
            opacity: 1, // 애니메이션 동안 보이도록 설정
            duration: 0.1,
            stagger: 0.05, // 각 문자 및 요소에 대한 지연 시간
            scrollTrigger: {
              id: 'typing-effect',
              trigger: textRef.current,
              start: isMobile ? 'top 50%' : 'start center',
              end: '+=300',
              scrub: 1,
              pin: false,
            },
          }
        );

        // 반응형 <br> 요소 처리
        gsap.set(textRef.current.querySelectorAll('.mo-br'), {
          display: isMobile ? 'block' : 'none',
        });
        gsap.set(textRef.current.querySelectorAll('.pc-br'), {
          display: isMobile ? 'none' : 'block',
        });
      }
    );

    // Cleanup to avoid conflicts
    return () => mm.revert();
  }, [children]);

  return <div ref={textRef} className="typing-effect2">{children}</div>;
};

export default TypingEffect2;
