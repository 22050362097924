import React from 'react';

const ProjectCard = ({ project }) => {
  // 클릭 시 페이지를 찾을 수 없을 때 처리
  const handleClick = (e) => {
    if (!project.href || project.href.startsWith('javascript:')) {
      e.preventDefault(); // 기본 동작 방지
      alert('페이지를 찾을 수 없습니다.');
    }
  };

  return (
    <a
      href={project.href ? project.href : '#'} // href가 있으면 사용, 없으면 기본값으로 #
      target={project.href ? '_blank' : '_self'} // href가 있을 때만 새 창에서 열림
      className="project-card"
      onClick={handleClick} // 클릭 이벤트 핸들러
      rel="noopener noreferrer"
    >
      <img src={project.thumbnail} alt={project.name} className="project-img" />
      <span className="project-text">
        <h3>{project.name}</h3>
        <p>{project.description}</p>
      </span>
    </a>
  );
};

export default ProjectCard;
