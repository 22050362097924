import React from 'react';

const steps = [
  {icon: '/images/main/step_01.png', description: '견적 및 방향성 상담'},
  {icon: '/images/main/step_02.png', description: '예산 확인'},
  {icon: '/images/main/step_03.png', description: '계약 진행'},
  {icon: '/images/main/step_04.png', description: '프로젝트 진행'},
  {icon: '/images/main/step_05.png', description: '피드백 반영'},
  {icon: '/images/main/step_06.png', description: '마무리 작업'},
];

const ProgressSteps = () => {
  return (
    <div className="progress-steps-container">
      <div className="progress-bar">
        {steps.map((step, index) => (
          <div className="step" key={`step-${index}`}>
            <img src={step.icon} alt={step.description} className="step-icon"/>
          </div>
        ))}
        {/* Progress line with points */}
        <div className="progress-line">
          {steps.map((_, index) => (
            <div className="progress-point-box" key={`point-box-${index}`}>
              <div className="progress-point" key={`point-${index}`}></div>
            </div>
          ))}
        </div>
        <div className="progress-line-m progress-line-m-1"></div>
        <div className="progress-line-m progress-line-m-2"></div>
        <div className="progress-line-m progress-line-m-3"></div>
      </div>
      <div className="progress-bar progress-bar2">
        {steps.map((step, index) => (
          <div className="step" key={`description-${index}`}>
            <div className="step-description">{step.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressSteps;
