import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const useScrollAnimation = (effect = 'fade-in', options = {}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    let animationConfig;

    switch (effect) {
      case 'fade-in':
        animationConfig = {
          from: { opacity: 0 },
          to: { opacity: 1, duration: 1 },
        };
        break;

      case 'slide-in-left':
        animationConfig = {
          from: { x: '-100%', opacity: 0 },
          to: { x: '0%', opacity: 1, duration: 1 },
        };
        break;

      case 'slide-in-right':
        animationConfig = {
          from: { x: '100%', opacity: 0 },
          to: { x: '0%', opacity: 1, duration: 1 },
        };
        break;

      case 'zoom-in':
        animationConfig = {
          from: { scale: 0.5, opacity: 0 },
          to: { scale: 1, opacity: 1, duration: 1 },
        };
        break;

      default:
        animationConfig = {
          from: { opacity: 0 },
          to: { opacity: 1, duration: 1 },
        };
        break;
    }

    gsap.fromTo(
      elementRef.current,
      animationConfig.from,
      {
        ...animationConfig.to,
        scrollTrigger: {
          trigger: elementRef.current,
          start: options.start || 'top 80%',  // 애니메이션 시작 위치
          end: options.end || 'top 30%',      // 애니메이션 끝나는 위치
          scrub: options.scrub || 1,          // 스크롤 동기화 옵션
          toggleActions: options.toggleActions || 'play none none reverse', // 스크롤 액션
        },
      }
    );
  }, [effect, options]);

  return elementRef;
};

export default useScrollAnimation;
