import React from 'react';
import {Link} from "react-router-dom";

const FooterCTA = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 부드러운 스크롤 효과
    });
  };
  return (
    <div className="footer-cta-container container">
      <div className="footer-cta-text">
        <h2>
          눈탱이 없는 <br className={`mo-br`}/><span className="highlight">합리적인 비용</span>으로<br/>
          <span className="highlight">퀄리티는 그 이상</span>으로
        </h2>
        <p>
          눈밤은 언제나<br/>
          고객님의 비전을 실현시키는 것에 중점을 둡니다.</p>
      </div>
      <Link to="/contact" onClick={handleScrollToTop}>
        <button className="cta-button">
          문의하기 <span className="cta-arrow"><img src={`/images/main/consult_arrow.png`}/></span>
        </button>
      </Link>
    </div>
  );
};

export default FooterCTA;
