import React from 'react';
import Portfolio from '../components/Portfolio';
import {Helmet, HelmetProvider} from 'react-helmet-async';

const PortfolioPage = () => {
  return (
    <HelmetProvider>
      <div className="portfolio-page sub-page">
        <Helmet>
          <title>포트폴리오 - 눈밤(SnowNight)</title>
          <meta name="description" content="눈밤의 포트폴리오를 통해 다양한 작업물들을 확인해보세요. 모든 프로젝트는 세심한 주의와 최신 기술을 반영하고 있습니다."/>
          <meta name="keywords" content="눈밤, snownight, 눈탱이밤탱이, 웹에이전시, 웹제작, 반응형, 퍼블리싱, 그누보드, 유지보수, 웹개발"/>
          <link rel="canonical" href="https://snownight.kr/portfolio"/>
        </Helmet>
        <Portfolio/>
      </div>
    </HelmetProvider>
  );
};

export default PortfolioPage;
