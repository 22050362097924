import React from 'react';
import useScrollAnimation from '../../hooks/useScrollAnimation'; // 커스텀 훅 가져오기

const AnimatedSection = ({effect = 'fade-in', children, className}) => {
  const ref = useScrollAnimation(effect); // 효과에 따라 애니메이션 적용

  return (
    <div ref={ref} className={`animated-section ${effect} ${className}`}>
      {children}
    </div>
  );
};

export default AnimatedSection;
