import React from 'react';
import Services from '../components/Services';
import {Helmet, HelmetProvider} from 'react-helmet-async';

const ServicesPage = () => {
  return (
    <HelmetProvider>
      <div className="services-page sub-page">
        <Helmet>
          <title>서비스 - 눈밤(SnowNight)</title>
          <meta name="description" content="고객님들이 원하는 홈페이지 눈밤은 다 만들 수 있습니다. 반응형 브랜드, 기업, 대학, 병원, 프랜차이즈까지!"/>
          <meta name="keywords" content="눈밤, snownight, 눈탱이밤탱이, 웹에이전시, 웹제작, 반응형, 퍼블리싱, 그누보드, 유지보수, 웹개발, 회원관리, 제품관리, 게시판관리, 메뉴관리, 접속자통계, 배너관리, 팝업관리, SNS로그인, 실명인증, 랜딩페이지, 채널톡, 카카오 알림톡, 네이버지도, 다음지도,"/>
          <link rel="canonical" href="https://snownight.kr/services"/>
        </Helmet>
        <Services/>
      </div>
    </HelmetProvider>
  );
};

export default ServicesPage;
