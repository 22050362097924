import React, {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import PortfolioPage from './pages/PortfolioPage';
import MainPage from "./pages/MainPage";
import './styles/Main.scss';
// 눈내리는 효과
import Snowflakes from 'magic-snowflakes';
const isMobile = window.innerWidth <= 768; // 모바일 화면인지 감지
const snowflakeInterval = isMobile ? 15 : 50; // 모바일에서는 눈 내리는 양을 1/3로 감소
const snowflakes = new Snowflakes({
  color: '#FFF', // Default: "#5ECDEF"
  container: document.querySelector('body'), // Default: document.body
  count: snowflakeInterval, // 100 snowflakes. Default: 50
  minSize: 5, // Default: 10
  maxSize: 20, // Default: 25
});
snowflakes.start();
const App = () => {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<MainPage/>}/>
        <Route path="/home" element={<HomePage/>}/>
        <Route path="/main" element={<MainPage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
        <Route path="/services" element={<ServicesPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/portfolio" element={<PortfolioPage/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
};

export default App;
