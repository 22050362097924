import React, { useState } from 'react';
import ProjectCard from './ProjectCard';

const Portfolio = () => {
  // 프로젝트 데이터
  const projects = [
    {id: 1, name: '비앤미 성형외과', thumbnail: '/images/main/pf_01.jpg', description: '웹사이트 총괄', type: '웹사이트', categories: '병원', href: 'https://benme.co.kr/sung'},
    {id: 2, name: 'KTM 남서울지점', thumbnail: '/images/main/pf_02.jpg', description: '웹사이트 총괄', type: '웹사이트', categories: '스포츠', href: 'https://ktm.seoul.kr/'},
    {id: 3, name: '프라이드 의원', thumbnail: '/images/main/pf_03.jpg', description: '웹 퍼블리싱', type: '웹사이트', categories: '병원', href: 'https://www.prideps.co.kr'},
    { id: 4, name: '라연한의원', thumbnail: '/images/main/pf_04.jpg', description: '웹 퍼블리싱', type: '웹사이트', categories: '병원', href: null, onClick: () => alert("페이지를 찾을 수 없습니다") },
    {id: 5, name: '연세이음정형외과', thumbnail: '/images/main/pf_05.jpg', description: '웹 퍼블리싱', type: '웹사이트', categories: '병원', href: null, onClick: () => alert("페이지를 찾을 수 없습니다")},
    {id: 6, name: '친절한누나들', thumbnail: '/images/main/pf_06.jpg', description: '시스템개발', type: '시스템', categories: '시스템', href: null, onClick: () => alert("페이지를 찾을 수 없습니다")},
    {id: 7, name: '비앤미 반영구센터', thumbnail: '/images/main/pf_07.jpg', description: '웹사이트 총괄', type: '웹사이트', categories: '병원', href: 'https://benme.co.kr/ban'},
  ];

  // 상태 관리
  const [visibleProjects, setVisibleProjects] = useState(6); // 기본적으로 6개만 보이도록 설정
  const [selectedType, setSelectedType] = useState(''); // 선택된 타입
  const [selectedCategory, setSelectedCategory] = useState(''); // 선택된 카테고리

  // 더보기 버튼을 눌렀을 때 6개씩 추가로 표시
  const loadMoreProjects = () => {
    setVisibleProjects(prev => prev + 6);
  };

  // 1차 필터링: type에 따른 필터링
  const filteredByType = projects.filter(project => {
    return selectedType === '' || project.type === selectedType;
  });

  // 2차 필터링: type이 선택된 후 category에 따른 필터링
  const filteredProjects = filteredByType.filter(project => {
    return selectedCategory === '' || project.categories === selectedCategory;
  });

  // 카테고리 목록을 동적으로 가져오기
  const availableCategories = [
    ...new Set(filteredByType.map(project => project.categories)),
  ];

  return (
    <section className="portfolio container">
      <div className="sub-title-box">
        <h2 className="sub-title">Service</h2>
        <p className="sub-subtitle">
          눈밤의 포트폴리오를 통해 다양한 작업물들을 확인해보세요.<br />
          모든 프로젝트는 세심한 주의와 최신 기술을 반영하고 있습니다.
        </p>
      </div>

      {/* 타입 필터 탭 */}
      <div className="filter-tabs">
        <button
          className={selectedType === '' ? 'active' : ''}
          onClick={() => { setSelectedType(''); setSelectedCategory(''); }}
        >
          전체
        </button>
        <button
          className={selectedType === '웹사이트' ? 'active' : ''}
          onClick={() => { setSelectedType('웹사이트'); setSelectedCategory(''); }}
        >
          웹사이트
        </button>
        <button
          className={selectedType === '시스템' ? 'active' : ''}
          onClick={() => { setSelectedType('시스템'); setSelectedCategory(''); }}
        >
          시스템
        </button>
      </div>

      {/* 1차 필터 선택 후 카테고리 필터 탭 표시 */}
      {selectedType && (
        <div className="filter-tabs">
          <button
            className={selectedCategory === '' ? 'active' : ''}
            onClick={() => setSelectedCategory('')}
          >
            전체
          </button>
          {availableCategories.map(category => (
            <button
              key={category}
              className={selectedCategory === category ? 'active' : ''}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      )}

      {/* 프로젝트 리스트 */}
      <div className="project-list">
        {filteredProjects.slice(0, visibleProjects).map(project => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>

      {/* 더보기 버튼 */}
      {visibleProjects < filteredProjects.length && (
        <button onClick={loadMoreProjects} className="load-more-btn">더보기</button>
      )}
    </section>
  );
};

export default Portfolio;
