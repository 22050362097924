import React from 'react';
import TechSlider from "./common/TechSlider";
import AnimatedSection from "./common/AnimatedSection";

const Services = () => {
  const serviceFunctions = [
    "SEO 최적화",
    "웹표준",
    "반응형 웹",
    "회원관리",
    "제품관리",
    "각종 게시판 관리",
    "카테고리 관리",
    "회원메일 발송",
    "접속자 집계 (통계)",
    "팝업관리",
    "배너관리",
    "메뉴관리",
    "이용약관/개인정보처리방침 설정",
    "포털사이트등록",
    "SSL 보안인증서 설치",
    "SMS 문자서비스",
  ];
  const easyFunctions = [
    {text: '회원관리', src: '/images/service/service-02.png'},
    {text: '제품관리', src: '/images/service/service-03.png'},
    {text: '메뉴관리', src: '/images/service/service-04.png'},
    {text: '게시판관리', src: '/images/service/service-05.png'},
    {text: '접속자 통계', src: '/images/service/service-06.png'},
    {text: '배너관리', src: '/images/service/service-07.png'},
    {text: '팝업관리', src: '/images/service/service-08.png'},
    {text: 'SNS 간편 로그인', src: '/images/service/service-09.png'},
    {text: '멀티 팝업', src: '/images/service/service-10.png'},
    {text: '실명인증 시스템 (휴대폰 인증, 본인인증)', src: '/images/service/service-11.png'},
    {text: '랜딩 페이지 관리', src: '/images/service/service-12.png'},
    {text: '채널톡', src: '/images/service/service-13.png'},
    {text: '카카오 알림톡', src: '/images/service/service-14.png'},
    {text: '네이버 지도 실시간 연동', src: '/images/service/service-15.png'},
    {text: '구글 지도 실시간 연동', src: '/images/service/service-16.png'},
    {text: '카카오 지도 실시간 연동', src: '/images/service/service-17.png'},
    {text: '카카오 기업메일', src: '/images/service/service-18.png'},
    {text: '인스타그램 연동', src: '/images/service/service-01.png'},
  ];
  return (
    <>
      <section id="services" className="services-section container">
        <div className={`sub-title-box`}>
          <h2 className={"sub-title"}>Service</h2>
          <p className={"sub-subtitle"}>
            고객님들이 원하는 홈페이지<br className={`mo-br`}/> 눈밤은 다 만들 수 있습니다.<br/>
            반응형 브랜드, 기업, 대학, 병원, 프랜차이즈까지!
          </p>
        </div>
        <div className={``}>
          <div className={`sub-title-box`}>
            <div className="sub-title">홈페이지 주요 기능</div>
          </div>
          <div className={`function`}>
            <div className={`function-row`}>
              {serviceFunctions.map((functionName, index) => (
                <div key={index} className={`function-row-item`}>
                  <div className="function-row-item-button">
                    {functionName}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className={`sub-content`}>
        <div className={``}>
          <div className={`sub-title-box`}>
            <div className="sub-title">편의기능</div>
          </div>
          <div className={`easy-function`}>
            {easyFunctions.map((item, index) => (
              <div key={index} className="easy-function-item">
                <img
                  className="easy-function-item-img"
                  src={item.src}
                  alt={item.text}
                />
                <div className="easy-function-item-text">{item.text}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="content-section">
        <div className="content-wrapper container">
          <div className="content-row right">
            <AnimatedSection effect="slide-in-left">
              <img src="/images/service/service-content-01.png" alt="Service Image 1"/>
            </AnimatedSection>
            <AnimatedSection effect="slide-in-right" className={`text-group`}>
              <div className="title">편리한 관리자 모드</div>
              <div className="description">
                웹사이트 관리자 모드로 모든 기능 사용이 가능합니다.<br/>
                로그인, 게시글 관리부터 팝업 관리 등등<br/>
                웹사이트 현황을 한눈에 파악/관리할 수 있습니다.
              </div>
            </AnimatedSection>
          </div>
          <div className="content-row left">
            <AnimatedSection effect="slide-in-left" className={`text-group`}>
              <div className="title">
                사용자 맞춤 커스텀
              </div>
              <div className="description">
                아이디어만 있다면<br/>
                복잡하고 어려운 기능도 제작 해드립니다.

              </div>
            </AnimatedSection>
            <AnimatedSection effect="slide-in-right">
              <img src="/images/service/service-content-02.png" alt="Service Image 2"/>
            </AnimatedSection>
          </div>
          <div className="content-row right">
            <AnimatedSection effect="slide-in-left">
              <img src="/images/service/service-content-03.png" alt="Service Image 3"/>
            </AnimatedSection>
            <AnimatedSection effect="slide-in-right" className={`text-group`}>
              <div className="title">반응형 웹사이트 기본</div>
              <div className="description">
                PC와 모바일 그리고 테블릿 해상도까지 맞춤으로<br/>
                화면을 조정하여 사용자에게 보여줍니다.<br/>
                특히 모바일 환경을 중심으로 리사이징을 하여<br/>
                사용자에게 편리함을 제공합니다.
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>
      <div className={`sub-title-box`}>
        <div className="sub-title">기술스텍</div>
      </div>
      <TechSlider/>
    </>
  );
};

export default Services;
