import React from 'react';
import Slider from 'react-slick';

const icons = [
  {src: "/images/main/html_icon.png", alt: "HTML5"},
  {src: "/images/main/css_icon.png", alt: "CSS3"},
  {src: "/images/main/js_icon.png", alt: "JavaScript"},
  {src: "/images/main/express_icon.png", alt: "Express JS"},
  {src: "/images/main/node_icon.png", alt: "Node JS"},
  {src: "/images/main/socketio_icon.png", alt: "Socket io"},
  {src: "/images/main/react_icon.png", alt: "React"},
  {src: "/images/main/php_icon.png", alt: "PHP"},
  {src: "/images/main/aws_icon.png", alt: "Aws"},
  {src: "/images/main/Linux_icon.png", alt: "Linux"},
  {src: "/images/main/mysql_icon.png", alt: "Mysql"}
];

const sliderSettingsLeft = {
  infinite: true,
  speed: 5000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  arrows: false,
  variableWidth: true,
};

const sliderSettingsRight = {
  ...sliderSettingsLeft,
  rtl: true, // 슬라이더를 오른쪽에서 왼쪽으로
};

const IconSlider = () => {
  return (
    <div className="slider-container">
      <Slider {...sliderSettingsLeft}>
        {icons.concat(icons).map((icon, index) => (
          <div className="icon" key={index}>
            <img src={icon.src} alt={icon.alt}/>
          </div>
        ))}
      </Slider>
      <Slider {...sliderSettingsRight}>
        {icons.concat(icons).map((icon, index) => (
          <div className="icon" key={index}>
            <img src={icon.src} alt={icon.alt}/>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default IconSlider;
