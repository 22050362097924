import React, {useEffect, useRef, useState} from 'react';
import TechSlider from "../components/common/TechSlider";
import TypingEffect from "../components/common/TypingEffect";
import TypingEffect2 from "../components/common/TypingEffect2";
import AnimatedSection from "../components/common/AnimatedSection";
import HALO from 'vanta/dist/vanta.halo.min'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Grid} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import ProgressSteps from "../components/common/ProgressSteps";
import {Link} from "react-router-dom";

const MainPage = () => {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(HALO({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        baseColor: 0x951daf,
        backgroundColor: 0x111111,
        amplitudeFactor: 3.00,
        xOffset: 0,
        yOffset: 0.1,
        size: 0.5
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 부드러운 스크롤 효과
    });
  };

  const slides = [
    {img: "/images/main/pf_01.jpg", title: "비앤미 성형외과", description: "웹사이트 총괄", tech: "그누보드, PHP, HTML, CSS, JAVASCRIPT, JQUERY"},
    {img: "/images/main/pf_02.jpg", title: "KTM 남서울지점", description: "웹사이트 총괄", tech: "그누보드, PHP, HTML, CSS, JAVASCRIPT, JQUERY"},
    {img: "/images/main/pf_03.jpg", title: "프라이드 의원", description: "웹 퍼블리싱", tech: "HTML, CSS, JAVASCRIPT, JQUERY"},
    {img: "/images/main/pf_04.jpg", title: "라연한의원", description: "웹사이트 총괄", tech: "그누보드, PHP, HTML, CSS, JAVASCRIPT, JQUERY"},
    {img: "/images/main/pf_05.jpg", title: "연세이음정형외과", description: "웹 퍼블리싱", tech: "HTML, CSS, JAVASCRIPT, JQUERY, TAILWIND"},
    {img: "/images/main/pf_06.jpg", title: "친절한누나들", description: "웹 시스템개발", tech: "HTML, CSS, JAVASCRIPT, CANVAS"},
  ];

  return (
    <div className="main-page">
      <div className="section container" ref={myRef}>
        <div className="main-text">
          <TypingEffect text={`눈밤과 함께 합리적인 비용으로\n웹사이트를 제작하세요.`}/>
        </div>
        <div className="text-center">
          <span className="white">눈밤은 '눈탱이 맞다'에서 영감을 받아,<br/></span>
          <span className="purple">과도한 비용 없이 최고의 웹 서비스를<br className={`mo-br`}/> 제공하는 것이 우리의 목표</span>
          <span className="white">입니다.</span>
        </div>
      </div>
      <div className={`container typingEffect2`}>
        <div className={`eclipse1`}>
          <img src={`/images/main/eclipse.png`}/>
        </div>
        <div className={`eclipse2`}>
          <img src={`/images/main/eclipse.png`}/>
        </div>
        <TypingEffect2>
          코드 하나로 창조하는 무한한 가능성<br/>
          눈밤과 함께하세요.
        </TypingEffect2>
      </div>

      <div className="content-section">
        <div className="content-wrapper container">
          <div className="content-row right">
            <AnimatedSection effect="slide-in-left">
              <img src="/images/main/Frame_01.png" alt="Service Image 1"/>
            </AnimatedSection>
            <AnimatedSection effect="slide-in-right" className={`text-group`}>
              <div className="title">맞춤형 웹사이트 개발</div>
              <div className="description">최신 기술을 활용하여 반응형 또는 PC/MO 분리형<br/>맞춤형 웹사이트를 완벽하게 제작합니다.</div>
            </AnimatedSection>
          </div>
          <div className="content-row left">
            <AnimatedSection effect="slide-in-left" className={`text-group`}>
              <div className="title">정기적 유지보수</div>
              <div className="description">지속적인 업데이트와 기술 지원을 통해 기능 추가,<br/>버그 수정 등을 합리적인 가격으로 유지해 드립니다.</div>
            </AnimatedSection>
            <AnimatedSection effect="slide-in-right">
              <img src="/images/main/Frame_02.png" alt="Service Image 2"/>
            </AnimatedSection>
          </div>
          <div className="content-row right">
            <AnimatedSection effect="slide-in-left">
              <img src="/images/main/Frame_03.png" alt="Service Image 3"/>
            </AnimatedSection>
            <AnimatedSection effect="slide-in-right" className={`text-group`}>
              <div className="title">웹 서비스 운영</div>
              <div className="description">웹사이트 운영을 위한 서버 관리부터 코드<br/>유지보수까지 안정적으로 관리합니다.</div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <div className="tech-section container">
        <div className="section-title">
          전문적인 기술력으로<br/>
          고객님이 원하는<br className={`mo-br`}/> 웹사이트를 제작합니다.
        </div>
      </div>
      <TechSlider/>
      <div className="cta-section container">
        <div className="cta-wrapper">
          <div className="cta-text">
            <div className="cta-subtitle">디테일 하나도 놓치지 않습니다.</div>
            <div className="cta-title">컨셉, 폰트, 색상, 정렬, 위치 모두 디테일하게<br/>하나하나 담아 제작합니다.</div>
            <Link to="/contact" onClick={handleScrollToTop} className="cta-button">문의하기</Link>
          </div>
          <AnimatedSection effect="zoom-in">
            <img src="/images/main/consult_img.png" alt="CTA Image"/>
          </AnimatedSection>
        </div>
      </div>
      <div className="portfolio-section container">
        <div className="section-title">눈밤이 이끌어낸 <br/>성공적인 결과를 확인하세요.</div>
        <div>
          <Swiper
            modules={[Autoplay, Grid]}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 3000, // 슬라이드가 자동으로 넘어가는 시간 (밀리초 단위)
              disableOnInteraction: false, // 사용자가 슬라이드에 상호작용해도 자동 슬라이드가 멈추지 않도록 설정
            }}
            breakpoints={{
              0: { // 768px 이하일 때 설정
                slidesPerView: 2,
                grid: {
                  rows: 2, // 2줄로 표시
                  fill: 'row', // 그리드 방향 설정 (row로 채움)
                },
                spaceBetween: 10, // 슬라이드 사이의 간격 조정
              },
              769: { // 769px 이상일 때 설정
                slidesPerView: 4,
                grid: {
                  rows: 1, // 1줄로 표시
                },
              },
            }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="slide-content">
                  <img src={slide.img} alt={slide.title}/>
                  <div className="slide-text">
                    <h3>{slide.title}</h3>
                    <p>{slide.description}</p>
                    <span>{slide.tech}</span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="step-section">
        <div className="section-title">복잡할 필요없는<br/>간단한 웹사이트 제작 절차</div>
        <div className={`step-box`}>
          <AnimatedSection effect="fade-in">
            <ProgressSteps/>
          </AnimatedSection>
        </div>
      </div>
    </div>
  )
}

export default MainPage